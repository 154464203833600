<template>
  <v-container class="not-found">
    <v-row no-gutters style="height: 100vh;">
      <v-col :align-self="'center'" justify-center class="text-center">
        <div class="text-vertical-center text-center">
          <v-img
            alt="logo DupliClick"
            width="300px"
            style="margin: 0 auto;"
            src="/logo/duplicarclick.svg"
          />
          <h1 class="font-weight-bold">Pagina non trovata!</h1>
          <h3>Ci dispiace. La pagina che hai cercato non è stata trovata.</h3>
          <h4>Ti invitiamo a effettuare una nuova ricerca.</h4>
          <router-link
            color="primary"
            style="text-decoration: none"
            class="font-weight-bold"
            :to="{ name: 'Home' }"
          >
            TORNA ALLA HOMEPAGE
          </router-link>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "NotFound"
};
</script>
<style lang="scss" scoped>
html,
body {
  width: 100% !important;
  height: 100% !important;
}
body {
  margin: 0 !important;
}
.not-found {
  display: table;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  padding: 0px !important;
  max-width: 100% !important;
  .text-vertical-center {
    text-align: center;
    vertical-align: middle;
    width: 100%;
  }
  .text-vertical-center h1 {
    margin: 0;
    padding: 0;
    color: $primary;
    margin: 0;
    padding: 18px 0;
    font: 900 35px;
  }
  .text-vertical-center h3 {
    margin: 0;
    padding: 5px 0;
    font: 800 22px;
  }
  .text-vertical-center h4 {
    margin: 0;
    padding: 0 0 30px 0;
    font: 400 20px;
  }
}
</style>
